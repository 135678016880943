import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Start Trailguide`}</h4>
    <p>{`Open `}<a parentName="p" {...{
        "href": "https://trailguide.net/"
      }}>{`trailguide.net`}</a>{` in your browser.`}</p>
    <br />
    <p>{`The start screen shows an overview of Europe. The numbers in the circles show
the number of tracks in the area.`}</p>
    <br />
    <p>{`Click on the numbers to zoom in. Or zoom and move the map with your fingers.
The default category is "MTB singletracks".`}</p>
    <br />
    <Image src="features/startscreen3.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`How to find trails`}</h4>
    <p>{`When you zoom in you starting seeing the individual tracks.`}</p>
    <br />
    <p>{`Click on a track to open the info bar with the most important info, click the
infobar to open a page with a descriptions, pictures, gps track, height
profile, condition reports and reviews.`}</p>
    <br />
    <p>{`Open a list of all the trails within the map area by clicking the arrow on the
left edge of the screen. Clicking an entry from the list will open the details
page.`}</p>
    <br />
    <p>{`From the details page, you can also download the GPX track for your GPS device.`}</p>
    <Image src="features/mapandlist2.png" className="w-1/2 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Search and filter`}</h4>
    <p>{`Search for content or locations in the search box at the top.`}</p>
    <br />
    <p>{`Open the filter to select the type of actitvity, filter by difficulty,
popularity and more.`}</p>
    <Image src="features/searchandfilter2.png" className="w-1/2 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`How to navigate`}</h4>
    <p>{`Make sure you have enabled GPS access to your web browser.`}</p>
    <br />
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/en-us/HT207092"
      }}>{`On iOS you must enable location services for Safari`}</a>{`.`}</p>
    <br />
    <p>{`Click the location button in the bottom left corner and give Trailguide access
to use your GPS unit.`}</p>
    <br />
    <p>{`You can now see your own location on the map.`}</p>
    <Image src="features/navigation2.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features" mdxType="Link">
  ← What is Trailguide?
    </Link>
    <Link className="float-right" to="/features/installation" mdxType="Link">
  Installation →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      